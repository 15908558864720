<template>
  <div>
    <v-card flat style="background: var(--v-toolbar-lighten) !important">
      <v-toolbar flat color="transparent">
        <v-toolbar-title>
          <v-btn icon @click="$router.go(-1)" class="mr-2">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          {{ item.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="saveModule()">
            <v-icon>save</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="4">
              <v-card>
                <v-card-title>
                  <v-icon>info</v-icon> Details
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-subtitle>
                  Basic module settings & details
                </v-card-subtitle>
                <v-card-text>
                  <v-text-field
                    label="Name"
                    v-model="item.name"
                    outlined
                    dense
                  ></v-text-field>
                  <v-textarea
                    label="Description"
                    v-model="item.description"
                    outlined
                  ></v-textarea>
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    Allowed Functions
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item v-for="(role, index) in roles" :key="index">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ role.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch
                          v-model="activeRoles"
                          color="primary"
                          :value="role.value"
                          @change="saveModuleRole()"
                          :error="!activeRoles.includes(role.value)"
                        ></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-card>
                <v-card-title>
                  <v-icon>merge</v-icon> Routes
                  <v-spacer></v-spacer>
                  <v-btn icon @click="routeModal = true">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle>
                  These are the frontend routes accessed by the client
                </v-card-subtitle>
                <v-card-text>
                  <v-list dense>
                    <el-input
                      prefix-icon="el-icon-search"
                      placeholder="Search"
                      v-model="searchRoute"
                      clearable
                    ></el-input>
                    <v-list-item v-for="route in routeFilter" :key="route.id">
                      <v-list-item-action>
                        <v-btn icon color="red" @click="removeRoute(route)">
                          <v-icon>remove_circle</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ route.route.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ route.route.path }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>
                  <v-icon>api</v-icon> Scopes
                  <v-spacer></v-spacer>
                  <v-btn icon @click="scopeModal = true">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle>
                  These are the scoped categories for API access
                </v-card-subtitle>
                <v-card-text>
                  <v-list dense>
                    <el-input
                      prefix-icon="el-icon-search"
                      placeholder="Search"
                      v-model="searchScope"
                      clearable
                    ></el-input>
                    <v-list-item v-for="scope in scopeFilter" :key="scope.id">
                      <v-list-item-action>
                        <v-btn icon color="red" @click="removeScope(scope)">
                          <v-icon>remove_circle</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ scope.scope.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ scope.scope.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-card>
                <v-card-title>
                  <v-icon>code</v-icon> Integrations
                  <v-spacer></v-spacer>
                  <v-btn icon @click="integrationModal = true">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle>
                  These are the integrations available with this module
                </v-card-subtitle>
                <v-card-text>
                  <v-list dense>
                    <el-input
                      prefix-icon="el-icon-search"
                      placeholder="Search"
                      v-model="searchActiveIntegration"
                      clearable
                    ></el-input>
                    <v-list-item v-for="integrationItem in filterIntegrations" :key="integrationItem.id">
                      <v-list-item-action>
              <v-avatar size="36" :color="integrationItem.countryIntegration.icon ?'white':'secondary'">
                <v-img v-if="integrationItem.countryIntegration.icon" :src="integrationItem.countryIntegration.icon" contain></v-img>
                <h3 v-else>{{ integrationItem.countryIntegration.name.charAt(0) }}</h3>
              </v-avatar>
            </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ integrationItem.countryIntegration.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ integrationItem.countryIntegration.organisationName }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="integrationItem.countryIntegration.countryRegion">
                          {{ integrationItem.countryIntegration.countryRegion.name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn :loading="removingIntegrationId==integrationItem.id" icon color="red" @click="removeModuleIntegration(integrationItem)">
                          <v-icon>remove_circle</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <el-dialog
      :visible.sync="routeModal"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="1000px"
      :title="'Add Route'"
      :closed="clearRouteModal"
    >
      <el-container>
        <el-header>
          <v-row justify="end">
            <v-col cols="12" sm="12" md="4" lg="6" class="text-left">
              <el-button
                v-if="
                  routeParams.filter &&
                  Object.keys(routeParams.filter).length > 0
                "
                type="plain"
                @click="clearRouteFilter"
                color="error"
              >
                <v-icon small>close</v-icon> Clear Filters
              </el-button>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="6">
              <el-input
                suffix-icon="el-icon-search"
                v-model="routeParams.search"
                clearable
                placeholder="Search"
                @keyup.enter.native="getRoutes()"
              >
              </el-input>
            </v-col>
          </v-row>
        </el-header>
        <el-main>
          <el-table
            v-loading="loading"
            ref="routeTable"
            :data="allRoutes.data"
            size="small"
            @filter-change="routeFilterChange"
            @selection-change="routeSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="filterSelectableRoutes"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              sortable
              label="Name"
              header-align="center"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="path"
              sortable
              label="Path"
              header-align="center"
              align="center"
            ></el-table-column>
            <el-table-column
              column-key="admin"
              prop="admin"
              sortable
              label="Admin"
              header-align="center"
              align="center"
              :filters="[
                { text: 'Admin', value: true },
                { text: 'Non-Admin', value: false },
              ]"
            >
              <template slot-scope="scope">
                <v-icon v-if="scope.row.admin" color="success"
                  >check_circle_outline</v-icon
                >
                <v-icon v-else color="red">cancel</v-icon>
              </template>
            </el-table-column>
            <el-table-column
              column-key="protected"
              prop="protected"
              sortable
              label="Protected"
              header-align="center"
              align="center"
              :filters="[
                { text: 'Protected', value: true },
                { text: 'Open', value: false },
              ]"
            >
              <template slot-scope="scope">
                <v-icon v-if="scope.row.protected" color="success"
                  >check_circle_outline</v-icon
                >
                <v-icon v-else color="red">cancel</v-icon>
              </template>
            </el-table-column>
            <el-table-column
              column-key="isActive"
              prop="isActive"
              sortable
              label="Status"
              header-align="center"
              align="center"
              :filters="[
                { text: 'Active', value: true },
                { text: 'Inactive', value: false },
              ]"
            >
              <template slot-scope="scope">
                <el-tag
                  style="cursor: pointer"
                  :type="scope.row.isActive ? 'primary' : 'danger'"
                  >{{ scope.row.isActive ? "Active" : "Inactive" }}</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer>
          <el-row justify="center" type="flex">
            <el-pagination
              style="color: var(--v-primaryText-base)"
              :current-page.sync="routePage"
              :page-size.sync="routeParams.limit"
              :page-sizes="[10, 25, 50, 100]"
              layout="sizes, prev, pager, next, jumper"
              :total="allRoutes.total"
            >
            </el-pagination>
          </el-row>
          <el-row justify="center" type="flex">
            <el-button
              :loading="loading"
              type="primary"
              :disabled="tempRoutes.length == 0"
              native-type="submit"
              @click="saveRoutes"
              block
            >
              {{ !loading ? "Save" : "" }}</el-button
            >
          </el-row>
        </el-footer>
      </el-container>
    </el-dialog>

    <el-dialog
      :visible.sync="scopeModal"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="1000px"
      :title="'Add Route'"
      :closed="clearScopeModal"
    >
      <el-container>
        <el-header>
          <v-row justify="end">
            <v-col cols="12" sm="12" md="4" lg="6" class="text-left">
              <el-button
                v-if="
                  scopeParams.filter &&
                  Object.keys(routeParams.filter).length > 0
                "
                type="plain"
                @click="clearScopeFilter"
                color="error"
              >
                <v-icon small>close</v-icon> Clear Filters
              </el-button>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="6">
              <el-input
                suffix-icon="el-icon-search"
                v-model="scopeParams.search"
                clearable
                placeholder="Search"
                @keyup.enter.native="getScopes()"
              >
              </el-input>
            </v-col>
          </v-row>
        </el-header>
        <el-main>
          <el-table
            v-loading="loading"
            ref="scopeTable"
            :data="allScopes.data"
            size="small"
            @filter-change="scopeFilterChange"
            @selection-change="scopeSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="filterSelectableScopes"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              sortable
              label="Name"
              header-align="center"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="path"
              sortable
              label="Path"
              header-align="center"
              align="center"
            ></el-table-column>
            <el-table-column
              column-key="admin"
              prop="admin"
              sortable
              label="Admin"
              header-align="center"
              align="center"
              :filters="[
                { text: 'Admin', value: true },
                { text: 'Non-Admin', value: false },
              ]"
            >
              <template slot-scope="scope">
                <v-icon v-if="scope.row.admin" color="success"
                  >check_circle_outline</v-icon
                >
                <v-icon v-else color="red">cancel</v-icon>
              </template>
            </el-table-column>
            <el-table-column
              column-key="protected"
              prop="protected"
              sortable
              label="Protected"
              header-align="center"
              align="center"
              :filters="[
                { text: 'Protected', value: true },
                { text: 'Open', value: false },
              ]"
            >
              <template slot-scope="scope">
                <v-icon v-if="scope.row.protected" color="success"
                  >check_circle_outline</v-icon
                >
                <v-icon v-else color="red">cancel</v-icon>
              </template>
            </el-table-column>
            <el-table-column
              column-key="isActive"
              prop="isActive"
              sortable
              label="Status"
              header-align="center"
              align="center"
              :filters="[
                { text: 'Active', value: true },
                { text: 'Inactive', value: false },
              ]"
            >
              <template slot-scope="scope">
                <el-tag
                  style="cursor: pointer"
                  :type="scope.row.isActive ? 'primary' : 'danger'"
                  >{{ scope.row.isActive ? "Active" : "Inactive" }}</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer>
          <el-row justify="center" type="flex">
            <el-pagination
              style="color: var(--v-primaryText-base)"
              :current-page.sync="scopePage"
              :page-size.sync="scopeParams.limit"
              :page-sizes="[10, 25, 50, 100]"
              layout="sizes, prev, pager, next, jumper"
              :total="allScopes.total"
            >
            </el-pagination>
          </el-row>
          <el-row justify="center" type="flex">
            <el-button
              :loading="loading"
              type="primary"
              :disabled="tempScopes.length == 0"
              native-type="submit"
              @click="saveScopes"
              block
            >
              {{ !loading ? "Save" : "" }}</el-button
            >
          </el-row>
        </el-footer>
      </el-container>
    </el-dialog>

    <v-dialog v-model="integrationModal" persistent width="500px">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">code</v-icon> Add Integration
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="saveIntegrations" :loading="savingIntegration">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon @click="integrationModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field outlined rounded placeholder="Search" dense hide-details v-model="searchIntegration" clearable></v-text-field>
         <v-list dense>
          <v-list-item v-for="detail in filteredIntegrationOptions" :key="detail.id" @click="selectIntegration(detail.id)">
            <v-list-item-action>
              <v-avatar size="36" :color="detail.icon ?'white':'secondary'">
                <v-img v-if="detail.icon" :src="detail.icon" contain></v-img>
                <h3 v-else>{{ detail.name.charAt(0) }}</h3>
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ detail.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ detail.organisationName }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="detail.countryRegion">
                {{ detail.countryRegion.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon v-if="selectedIntegrations.includes(detail.id)" color="success">check_circle_outline</v-icon>
            </v-list-item-action>
          </v-list-item>
         </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    activeRoles: [],
    allRoutes: {},
    allScopes: {},
    integration: {},
    integrations: [],
    integrationModal: false,
    integrationOptions: [],
    item: Object,
    removingIntegrationId: null,
    routePage: 1,
    routeParams: {
      limit: 10,
      offset: 0,
      search: null,
    },
    roles: [],
    routes: {},
    routeModal: false,
    savingIntegration: false,
    searchActiveIntegration: null,
    searchRoute: "",
    searchScope: "",
    scopePage: 1,
    scopeParams: {
      limit: 10,
      offset: 0,
      search: null,
    },
    scopes: [],
    scopeModal: false,
    searchIntegration: null,
    selectedIntegrations: [],
    tempRoutes: [],
    tempScopes: [],
  }),
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadModule(value);
        }
      },
    },
    async routePage(value) {
      this.routeParams.offset = (value - 1) * this.routeParams.limit;
      await this.getRoutes();
    },
    "routeParams.limit": {
      immediate: true,
      async handler() {
        await this.getRoutes();
      },
    },
    async scopePage(value) {
      this.scopeParams.offset = (value - 1) * this.scopeParams.limit;
      await this.getScopes();
    },
    "scopeParams.limit": {
      immediate: true,
      async handler() {
        await this.getScopes();
      },
    },
  },
  computed: {
    filterIntegrations(){
      let result = this.integrations
      if(this.searchActiveIntegration){
        result = result.filter(x=>x.countryIntegration.name.toLowerCase().includes(this.searchActiveIntegration.toLowerCase()))
      }
      return result
    },
    filteredIntegrationOptions(){
      let result = this.integrationOptions.filter(x=>!this.integrations.map(y=>y.countryIntegrationId).includes(x.id))
      if(this.searchIntegration){
        result = result.filter(x=>x.name.toLowerCase().includes(this.searchIntegration.toLowerCase()))
      }
      return result
    },
    routeFilter() {
      let result = this.routes;
      if (this.searchRoute) {
        result = result.filter(
          (x) =>
            x.route.name
              .toLowerCase()
              .includes(this.searchRoute.toLowerCase()) ||
            x.route.path.toLowerCase().includes(this.searchRoute.toLowerCase())
        );
      }
      return result;
    },
    scopeFilter() {
      let result = this.scopes;
      if (this.searchScope) {
        result = result.filter((x) =>
          x.scope.name.toLowerCase().includes(this.searchScope.toLowerCase())
        );
      }
      return result;
    },
  },
  async created() {
    this.roles = await this.$API.getSystemRoles()
    this.getRoutes();
    this.getScopes();
    this.getCountryIntegrations();
  },
  methods: {
    async loadModule(id) {
      this.item = await this.$API.getModule(id);
      this.getModuleRoutes();
      this.getModuleScopes();
      this.getModuleRoles();
      this.getModuleIntegrations()
    },

    // Routes
    clearRouteFilter() {
      this.$refs.routeTable.clearFilter();
      this.routeParams.filter = {};
      this.getRoutes();
    },
    clearRouteModal() {
      this.tempRoutes = [];
      this.routeModal = false;
    },
    filterSelectableRoutes(row) {
      let selectedRoutes = this.routes.map((x) => x.routeId);
      return !selectedRoutes.includes(row.id);
    },
    routeSelectionChange(data) {
      this.tempRoutes = data;
    },
    async getCountryIntegrations() {
      this.loading = true;
      this.integrationOptions = await this.$API.getAllAdminCountryIntegrations();
      this.loading = false;
    },
    async getModuleRoutes() {
      this.loading = true;
      this.routes = await this.$API.getModuleRoute(this.item.id);
      this.loading = false;
    },
    async getRoutes() {
      this.loading = true;
      this.allRoutes = await this.$API.getRoutes({
        params: this.routeParams,
      });
      this.loading = false;
    },
    removeRoute(route) {
      this.$confirm("Are you sure you want to delete this route?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          await this.$API.updateModuleRoute({
            id: route.id,
            isActive: false,
            isDeleted: true,
          });
          this.getModuleRoutes();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async saveModuleRole() {
      await this.$API.setModuleRole(
        this.item.id,
        this.activeRoles
      );
      this.$message.success("Successfully updated module roles!");
    },
    routeFilterChange(data) {
      this.routeParams.filter = data;
      this.getRoutes();
    },
    async saveRoutes() {
      let routes = this.tempRoutes.map((x) => ({
        routeId: x.id,
        systemModuleId: this.item.id,
      }));
      await this.$API.createModuleRoute({
        batch: routes,
      });
      this.getModuleRoutes();
      this.clearRouteModal();
    },

    // Scopes
    clearScopeFilter() {
      this.$refs.scopeTable.clearFilter();
      this.scopeParams.filter = {};
      this.getScopes();
    },
    clearScopeModal() {
      this.tempScopes = [];
      this.scopeModal = false;
    },
    filterSelectableScopes(row) {
      let selectedScopes = this.scopes.map((x) => x.scopeId);
      return !selectedScopes.includes(row.id);
    },
    scopeSelectionChange(data) {
      this.tempScopes = data;
    },
    async getModuleRoles() {
      this.loading = true;
      let roles = await this.$API.getModuleRoles(this.item.id);
      this.activeRoles = roles.map((x) => x.role);
      this.loading = false;
    },
    async getModuleScopes() {
      this.loading = true;
      this.scopes = await this.$API.getModuleScope(this.item.id);
      this.loading = false;
    },
    async getModuleIntegrations() {
      this.loading = true;
      this.integrations = await this.$API.getModuleIntegrations(this.item.id);
      this.loading = false;
    },
    async createModuleIntegration() {
      await this.$API.createModuleIntegration({
        systemModuleId: this.item.id,
        countryIntegrationId: this.integration.id,
      });
      this.getModuleIntegrations();
    },
    async removeModuleIntegration(integration) {
      this.removingIntegrationId = integration.id;
      await this.$API.updateModuleIntegration({
        id: integration.id,
        isActive: false,
        isDeleted: true,
      });
      this.removingIntegrationId = null;
      this.integrations = this.integrations.filter((x) => x.id != integration.id);
    },
    async updateModuleIntegration(integration) {
      await this.$API.updateModuleIntegration({
        id: integration.id,
        isActive: false,
        isDeleted: true,
      });
      this.getModuleIntegrations();
    },
    async getScopes() {
      this.loading = true;
      this.allScopes = await this.$API.getScopes({
        params: this.scopeParams,
      });
      this.loading = false;
    },
    removeScope(scope) {
      this.$confirm("Are you sure you want to delete this scope?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          await this.$API.updateModuleScope({
            id: scope.id,
            isActive: false,
            isDeleted: true,
          });
          this.getModuleScopes();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async saveModule(){
        await this.$API.updateModule(this.item)
        this.$message.success("Successfully updated module!");
    },
    selectIntegration(id){
      if(this.selectedIntegrations.includes(id)){
        this.selectedIntegrations = this.selectedIntegrations.filter(x=>x!=id)
      } else {
        this.selectedIntegrations.push(id)
      }
    },
    scopeFilterChange(data) {
      this.scopeParams.filter = data;
      this.getScopes();
    },
    async saveIntegrations(){
      this.savingIntegration = true
      let batch = this.selectedIntegrations.map(x=>({systemModuleId: this.item.id, countryIntegrationId: x}))
      await this.$API.createModuleIntegration({batch})
      this.getModuleIntegrations()
      this.integrationModal = false
      this.searchIntegration = null
      this.savingIntegration = false
    },
    async saveScopes() {
      let scopes = this.tempScopes.map((x) => ({
        scopeId: x.id,
        systemModuleId: this.item.id,
      }));
      await this.$API.createModuleScope({
        batch: scopes,
      });
      this.getModuleScopes();
      this.clearScopeModal();
    },
  },
};
</script>
